<template>
  <section class="hero d-flex align-items-center text-white text-center">
    <div class="container">
      <h1><span class="highlight">Book An Appointment</span></h1>
      <p>Please, fill out the form to book your appointment.<br />
        You will receive a booking confirmation within 24 - 48 hours.</p>
      <h5>For last minute inquiries, please text us at <span class="highlight">1(347)481-8056</span></h5>
    </div>
  </section>
  <section class="py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div v-if="formSubmitted" class="success-message">
            <p>Thank you for booking an appointment with us! We will contact you with further details.</p>
            <button @click="resetForm" class="btn btn-warning btn-block">Book Another Appointment</button>
          </div>
          <div v-else>
            <form class="content-block contact-form p-4" id="book-form" @submit.prevent="submitForm">
              <h3>Appointment Information</h3>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="competition" class="highlight">Select Dance Competition</label>
                  <select class="form-control contact-input" id="competition" v-model="form.competition" @change="updateEventDays" required>
                    <option disabled selected>---SELECT---</option>
                    <option v-for="event in events" :key="event._id" :value="event._id">
                      {{ event.eventName }} ({{ formatDate(event.eventStartDate) }} - {{ formatDate(event.eventEndDate) }})
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
									<label for="dance-level" class="highlight">Dance Level Category</label>
									<select class="form-control contact-input" id="dance-level" v-model="form.danceLevel" required>
										<option disabled selected>---SELECT---</option>
										<option value="junior">Junior</option>
										<option value="youth">Youth</option>
										<option value="amateur">Amateur</option>
										<option value="pro-am">Pro-Am</option>
										<option value="professional">Professional</option>
									</select>
								</div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6" v-if="form.competition">
                  <label for="event-days" class="highlight">Select Appointment Days</label>
                  <select
                    multiple
                    class="form-control contact-input"
                    id="event-days"
                    v-model="form.selectedEventDays"
                    size="7"
                    @change="updateDayFields"
                    required
                  >
                    <option v-for="day in eventDays" :key="day.date" :value="day.date">{{ day.dayName }}</option>
                  </select>
                </div>
              </div>

              <!-- Dynamic Fields Per Day -->
              <div v-for="(dayFields, index) in form.dayDetails" :key="index" :class="['day-field-group', `group-${index}`]">
                <h3>Services for {{ formatDateVerbose(dayFields.date) }}</h3>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label :for="'services-' + index" class="highlight">Services</label>
                    <select
                      class="form-control contact-input"
                      :id="'services-' + index"
                      v-model="dayFields.services"
                      required
                    >
                      <option disabled value="">Select Service</option>
                      <option disabled>--- WOMEN ---</option>
                      <option v-for="service in categorizedServices.Women" :key="service._id" :value="service._id">
                        {{ service.title }}
                      </option>
                      <option disabled>--- MEN ---</option>
                      <option v-for="service in categorizedServices.Men" :key="service._id" :value="service._id">
                        {{ service.title }}
                      </option>
                      <option disabled>--- YOUTH ---</option>
                      <option v-for="service in categorizedServices.Youth" :key="service._id" :value="service._id">
                        {{ service.title }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="highlight">Additional Services</label>
                    <div class="form-check" v-for="service in additionalServices" :key="service._id">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'additional-' + service._id + '-' + index"
                        :value="service._id"
                        v-model="dayFields.additionalServices"
                      />
                      <label class="form-check-label" :for="'additional-' + service._id + '-' + index">
                        {{ service.title }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label :for="'dance-style-' + index" class="highlight">Dance Style</label>
                    <select
                      multiple
                      class="form-control contact-input dance-style"
                      :id="'dance-style-' + index"
                      v-model="dayFields.danceStyle"
                      required
                    >
                      <option value="latin">Latin</option>
                      <option value="standard">Standard</option>
                      <option value="smooth">Smooth</option>
                      <option value="rhythm">Rhythm</option>
                      <option value="exhibition">Exhibition / Theater Arts</option>
                      <option value="night-club">Night Club</option>
                      <option value="show-dance">Show Dance</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
              </div>

              <!-- Stylist Preference -->
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="stylist-preference" class="highlight">Stylist Preference</label>
                  <input type="text" class="form-control contact-input" id="stylist-preference" v-model="form.stylistPreference">
                </div>
              </div>

              <!-- Are you open to doing your hair the night before -->
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="hair-at-night" class="highlight">Are you open to doing your hair the night before?</label>
                  <select
                    class="form-control contact-input"
                    id="hair-at-night"
                    v-model="form.hairAtNight"
                    required
                  >
                    <option disabled selected>---SELECT---</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>

              <!-- Contact Information Section -->
              <h3>Contact Information</h3>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="firstName" class="highlight">First Name</label>
                  <input type="text" class="form-control contact-input" id="firstName" v-model="form.firstName" required>
                </div>
                <div class="form-group col-md-6">
                  <label for="lastName" class="highlight">Last Name</label>
                  <input type="text" class="form-control contact-input" id="lastName" v-model="form.lastName" required>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="email" class="highlight">Email</label>
                  <input type="email" class="form-control contact-input" id="email" v-model="form.email" required>
                </div>
                <div class="form-group col-md-6">
                  <label for="phoneNumber" class="highlight">Phone Number</label>
                  <input type="text" class="form-control contact-input" id="phoneNumber" v-model="form.phoneNumber" required>
                </div>
              </div>
              <div class="form-group">
                <label for="message" class="highlight">Anything else you would like us to know?</label>
                <textarea class="form-control contact-input" id="message" v-model="form.message" rows="4"></textarea>
              </div>
              <VueHcaptcha
                :sitekey="hCaptchaSiteKey"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                @error="onCaptchaError"
              />
              <button type="submit" class="btn btn-warning btn-block" :disabled="!captchaToken || formInSubmission">
                Book Appointment <span>&#8594;</span>
              </button>
            </form>
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { fetchUpcomingEvents, formatDate } from '@/utils/events';
import { fetchServices } from '@/utils/servicesList';

export default {
  name: 'SiteBookAnAppointment',
  components: {
    VueHcaptcha,
  },
  data() {
    return {
      categorizedServices: {
        Women: [],
        Men: [],
        Youth: [],
      },
      additionalServices: [],
      form: {
        competition: '',
        danceLevel: '',
        selectedEventDays: [],
        dayDetails: [],
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        stylistPreference: '',
        hairAtNight: '',
        message: '',
        captchaToken: '',
      },
      formSubmitted: false,
      formInSubmission: false,
      errorMessage: '',
      captchaToken: '',
      hCaptchaSiteKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
      events: [],
      eventDays: [],
    };
  },
  created() {
    this.loadEvents().catch((error) => {
      console.error('Failed to load events:', error);
    });
    this.loadServices().catch((error) => {
      console.error('Failed to load services:', error);
    });
  },
  methods: {
    formatDate,
    formatDateVerbose(date) {
      return moment.utc(date).format('dddd, MMMM Do YYYY');
    },
    async loadEvents() {
      try {
        this.events = await fetchUpcomingEvents();
      } catch (error) {
        console.error('Error loading events:', error);
      }
    },
    async loadServices() {
      try {
        const services = await fetchServices();
        this.categorizedServices = this.categorizeServices(services);
        this.additionalServices = services.filter(
          (service) => service.category === 'Additional'
        );
      } catch (error) {
        console.error('Error loading services:', error);
        this.categorizedServices = { Women: [], Men: [], Youth: [] };
        this.additionalServices = [];
      }
    },
    categorizeServices(services) {
      const categorized = { Women: [], Men: [], Youth: [] };
      services.forEach((service) => {
        if (service.category === 'Women') categorized.Women.push(service);
        else if (service.category === 'Men') categorized.Men.push(service);
        else if (service.category === 'Youth') categorized.Youth.push(service);
      });
      return categorized;
    },
    async updateEventDays() {
      const selectedEvent = this.events.find((event) => event._id === this.form.competition);
      if (!selectedEvent) {
        this.eventDays = [];
        return;
      }

      const startDate = moment.utc(selectedEvent.eventStartDate);
      const endDate = moment.utc(selectedEvent.eventEndDate);
      const days = [];

      let currentDate = startDate.clone();
      while (currentDate <= endDate) {
        days.push({
          date: currentDate.format('YYYY-MM-DD'),
          dayName: currentDate.format('dddd, MMMM Do YYYY'),
        });
        currentDate.add(1, 'days');
      }

      this.eventDays = days;
    },
    updateDayFields() {
      const selectedDays = this.form.selectedEventDays;
      this.form.dayDetails = selectedDays.map((day) => ({
        date: day,
        services: '',
        danceStyle: [],
        additionalServices: [],
      }));
    },
    resetForm() {
      this.form = {
        competition: '',
        danceLevel: '',
        selectedEventDays: [],
        dayDetails: [],
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        stylistPreference: '',
        hairAtNight: '',
        message: '',
        captchaToken: '',
      };
      this.formSubmitted = false;
      this.formInSubmission = false;
      this.errorMessage = '';
      this.captchaToken = '';
      this.eventDays = [];
    },
    async submitForm() {
      this.errorMessage = '';
      this.formInSubmission = true;
      
      // Validate dayDetails
      for (const [index, day] of this.form.dayDetails.entries()) {
        if (!day.services) {
          this.errorMessage = `Please select a service for day ${index + 1}.`;
          this.formInSubmission = false;
          return;
        }
        if (!day.danceStyle || day.danceStyle.length === 0) {
          this.errorMessage = `Please select at least one dance style for day ${index + 1}.`;
          this.formInSubmission = false;
          return;
        }
      }
      
      if (!this.captchaToken) {
        this.errorMessage = 'Please complete the CAPTCHA.';
        this.formInSubmission = false;
        return;
      }

      try {
        const response = await fetch('/api/booking/create', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.form),
        });

        if (response.ok) {
          this.formSubmitted = true;
        } else {
          this.errorMessage = 'Failed to send booking request. Please try again.';
        }
      } catch (error) {
        this.errorMessage = `Error: ${error.message}`;
      }
    },
    onCaptchaVerified(token) {
      this.captchaToken = token;
      this.form.captchaToken = token;
    },
    onCaptchaExpired() {
      this.captchaToken = '';
      this.form.captchaToken = '';
    },
    onCaptchaError() {
      this.captchaToken = '';
      this.form.captchaToken = '';
    },
  },
};
</script>

<style scoped>
.success-message {
  color: #e2b06e;
}

.error-message {
  color: red;
}

.day-field-group {
  border-bottom: 1px solid #e2b06e;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.day-field-group.group-0 {
  border-top: 1px solid #e2b06e;
  padding-top: 1rem;
}

.dance-style {
  overflow-y: hidden;
  height: 190px !important;
}
</style>
